<template>
  <div class="card2">
     
    <div class="card2Top">
      <div class="image-container2">
        <img :src="info.crowdfundPic" alt="Image" class="image2" />
      </div>
      <div class="text-container2">
        <div class="text-left2">
          <span class="title2">{{ info.name }}</span>
        </div>
        <div class="text-right2">
          <span class="right-text">$503.12</span>
        </div>
      
      </div>
     
    </div>

    <div class="precent-container">
      <div class="bottom-layout2">
        <img :src="ic_up_arrow" alt="Icon" class="icon2" />
        <span class="bottom-text">12%</span>
      </div>

    </div>

    <div class="grid-container">
      <div class="grid-item grid-title">80%</div>
      <div class="grid-item grid-title">28.7K</div>
   
      <div class="grid-item grid-subtext">Crowdfunding</div>
      <div class="grid-item grid-subtext">Holders</div>
    </div>
    <ProcessView :width="50" />
  </div>

</template>

<script>
import ProcessView from "@/components/widget/Process.vue";


export default {
  components:{
    ProcessView
  },
  props: {
      info: {
        type: Object,
        required: true,
      }
    },
  data() {
    return {
      ic_up_arrow: require('@/assets/svg/ic_up_arrow.svg'),
      iconSrc2: require('@/assets/svg/ic_twitter_small.svg'),
      userInfo:{
        name:"Tim Bakes",
        follower:"628.7k followers",
        headPic:'https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690',
        showVip:true,
        showTwitter:true,
        isFollowed:false,
      }

    };
  },
};
</script>

<style lang="scss" scoped>



.card2 {
  background-color: $whiteColor;
  border-radius: 16px;
  border: 1px solid $bgColor_8; /* 边框颜色，8% #000000 */
  margin: 12px 16px 16px; /* 上边距12px, 左右边距16px, 下边距16px */
  padding: 12px;
}

.card2Top {
  display: flex;
  height: 24px;
}

.image-container2 {
  margin-right: 6px; /* 图像和文字之间的间距 */
}

.image2 {
  width: 24px; /* 图像宽度 */
  height: 24px; /* 图像高度 */
  border-radius: 50%; /* 圆形图像 */
}

.text-container2 {
  flex: 1; /* 占据剩余空间 */
  display: flex;
  height: 24px;
  justify-content: space-between; /* 两侧对齐 */
  align-items: center; /* 垂直居中对齐子项 */
}

.text-right2 {
  height: 24px;
  display: flex;
  align-items: center; /* 垂直居中对齐子项 */
  font-size: 14px; /* 字体大小 */
  font-weight: 700; /* 字重 */
  color: $txtColor; /* 文字颜色 */
}
.text-left2{
  height: 24px;
  display: flex;
  align-items: center; 
  font-size: 14px; /* 字体大小 */
  font-weight: 700; /* 字重 */
  color:  $txtColor; /* 文字颜色 */
}

.right-text {
  margin-left: 10px; /* 文字左侧间距 */
}

.text-left {
  display: flex;
  align-items: center;
}

.title2 {
  font-size: 14px;
  font-weight: 700;
  color:  $txtColor;
}


.right-text {
  font-size: 15px;
  font-weight: 700;
  color:  $txtColor;
  margin-bottom: 4px;
}

.precent-container {
  display: flex;
  justify-content: flex-end; /* 将子元素对齐到右边 */
}

.bottom-layout2 {
  display: flex;
  justify-content: center; 
  font-size: 16px;
  color: $txtColor_green;
}

.icon2 {

}

.bottom-text {
  font-size: 12px;
  font-weight: 500;
  color: $txtColor_green;
  ;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 100px); /* 2列，每列宽度自适应 */
  grid-template-rows: repeat(2, auto); /* 2行，高度自适应内容 */
  gap: 2px 10px; /* 列间距和行间距都是10px */
  padding: 0px; /* 可选：给容器添加内边距 */
  margin-top: 30px;
  
}

.grid-item {
  display: flex;
  justify-content: flex-start; /* 左对齐内容 */
  box-sizing: border-box; /* 确保内边距不会影响单元格宽度 */
  /* 去掉背景和边框 */
  background-color: transparent;
  border: none;
}
.grid-title {
  font-size: 14px;
  font-weight: 700;
  color: $txtColor;
}

.grid-subtext {
  font-size: 12px;
  font-weight: 400;
  color: $txtSubColor;
}

</style>
