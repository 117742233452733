<template>
    <div class="box-container ">
  
        <div class="tabs">
          <div class="tabs-wrapper">
            <div
              v-for="tab in tabs"
              :key="tab.name"
              class="tab-item"
              :class="{ active: currentTab === tab.name }"
              @click="setActiveTab(tab.name)">
              {{ tab.label }}
            </div>
            <div class="indicator" :style="indicatorStyle"></div>
          </div>
        </div>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentTab: '0',
        tabs: [
          { name: '0', label: 'Follow', content: 'Content for A' },
          { name: '1', label: 'Hot', content: 'Content for B' },
          { name: '2', label: 'News', content: 'Content for C' },
        ],
      };
    },
    computed: {
      indicatorStyle() {
        const activeTab = this.tabs.find(tab => tab.name === this.currentTab);
        const index = this.tabs.indexOf(activeTab);
        return {
          transform: `translateX(${index * 100}%)`,
        };
      },
    },
    methods: {
      setActiveTab(tabName) {
        this.currentTab = tabName;
        this.$emit('tab-changed', tabName); // 触发tab-changed事件并传递当前tabName
      },
    },
    mounted() {
        this.$emit('tab-changed', this.currentTab); // 组件初始化时触发事件并传递当前选中的tabName
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .tabs-container {
    width: 192px;
  }
  
  .box-container {
    width: 200px;
    height: 42px;
    border-radius: 50px;
    padding-left: 4px;
    padding-right: 4px;
    background: $bgColor_5;
  }
  
  
  .tabs {
    position: relative;
    height: 42px;
    width: 192px;
    display: flex;
    justify-content: center;
   
    overflow: hidden;
  }
  
  .tabs-wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }
  
  .tab-item {
    flex: 1;
    text-align: center;
    line-height: 42px; /* 文字垂直居中 */
    width: 64px;
    cursor: pointer;
    position: relative;
    color: $txtSubColor; 
    z-index: 1;
    transition: color 0.3s;
  
    -webkit-tap-highlight-color: transparent; /* 透明点击效果 */
  }
  
  /* 取消水波纹效果 */
  .tab-item:focus,
  .tab-item:active,
  .tab-item:hover {
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
  
  .tab-item.active {
    color: $txtColor; /* 选中的文字颜色 */
  }
  
  .indicator {
    position: absolute;
    bottom: 4px; /* 调整到中间 */
    height: 34px; /* 指示器高度 */
    background-color: $whiteColor; /* 指示器颜色 */
    transition: transform 0.3s ease; /* 平移效果 */
    border-radius: 50px; /* 胶囊样式 */
    width: 64px;
  }
  
  
  </style>
  