<template>
    <div class="container">
      <div class="black-rectangle">
        <div 
          class="red-rectangle" 
          :style="{ width: `${redWidth}%` }">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ColoredRectangle',
    props: {
      width: {
        type: Number,
        required: true,
        validator: (value) => value >= 0 && value <= 100
      }
    },
    computed: {
      redWidth() {
        return this.width; // 使用传入的宽度值
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .container {
    margin-top: 16px;
  }
  
  .black-rectangle {
    background-color: $bgColor_5; /* 黑色背景 */
    border-radius: 5px; /* 圆角矩形 */
    height: 8px; /* 高度 */
    position: relative; /* 用于定位红色矩形 */
  }
  
  .red-rectangle {
    background-color: $txtColor_red; /* 红色背景 */
    border-radius: 5px; /* 圆角矩形 */
    height: 8px; /* 高度 */
    position: absolute; /* 绝对定位覆盖在黑色矩形上 */
    top: 0; /* 顶部对齐 */
    left: 0; /* 左侧对齐 */
  }
  </style>
  