<template>
    <div class="topbox">
        <div class="image-container">
          <img :src="info.headPic" alt="Image" class="image" />
        </div>
        <div class="content">
          <div class="top-layout">
            <span class="title">{{ info.name }}</span>
            <div class="icons">
              <img :src="iconSrc1" alt="Icon 1" class="icon"  v-if="info.showVip"/>
              <img :src="iconSrc2" alt="Icon 2" class="icon"   v-if="info.showTwitter"/>
            </div>
          </div>
          <div class="bottom-layout">
            <span class="subtitle"> {{ info.follower }}</span>
          </div>
        </div>
        <div :class="[ info.isFollowed ? 'line' : 'followBg']">
          <span :class="[ info.isFollowed ? 'line-text' : 'follow-text']">
           {{ info.isFollowed ? $t('following') : $t('follow')   }}
        </span>
        </div>
       
      </div>
     
</template>
  
  <script>
 
  export default {
    name:'FollowHeader',

    props: {
      info: {
        type: Object,
        required: true,
      }
    },

    components:{
  
    },
    data() {
      return {
        imageSrc: 'https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690',
        iconSrc1: require('@/assets/svg/ic_symble.svg'),
        iconSrc2: require('@/assets/svg/ic_twitter_small.svg'),
  
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>

  .topbox{
    
    padding: 16px;
    display: flex;
    align-items: center;
  }
  
  .image-container {
    margin-right: 12px;
  }
  
  .image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .content {
    flex: 1;
  }
  
  .top-layout {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .title {
    font-size: 14px;
    font-weight: 500;
    margin-right: 12px;
  }
  
  .icons {
    display: flex;
  }
  
  .icon {
    width: 12px;
    height: 12px;
    margin-left: 4px;
  }
  
  .bottom-layout {
    font-size: 12px;
    color: $txtSubColor;
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
  
  .line {
    display: flex;
    align-items: center;
    border: 0.5px solid $bgColor_1;
    border-radius: 30px;
    height: 28px;
    width: 79px;
    justify-content: center;
  }
  
  .followBg {
    display: flex;
    align-items: center;
    border-radius: 30px;
    height: 28px;
    width: 79px;
    background: $themeColor;
    justify-content: center;
  }


  .follow-text {
    font-size: 12px;
    color: $whiteColor;
  }
  .line-text {
    font-size: 12px;
    color: $txtSubColor;
  }
  
  
  
  </style>
  