<template>
    <div >
      <div class="header-text">
        BASIC  TASKS
      </div>
      <div class="items-container">
        <div class="item" v-for="item in items" :key="item.id">
          <img :src="item.imageSrc" alt="Item Image" class="item-image" />
          <div class="item-text">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-subtitle">{{ item.subtitle }}</div>
          </div>
          <button 
            :class="['item-button', { 'active': item.isActive }]" 
            @click="onButtonClick(item)">
            <div v-if="item.isActive">Go</div>
            <div v-else>
              <img :src="require('@/assets/svg/ic_complete.svg')" alt="Icon" class="button-icon" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          {
            id: 1,
            imageSrc: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Telegram_Messenger.png',
            title: 'Join the news channel',
            subtitle: '+10',
            isActive: false,
           
          },
          {
            id: 2,
            imageSrc: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Telegram_Messenger.png',
            title: 'Follow us on Twitter(X)',
            subtitle: '+20',
            isActive: true,
        
          }
          // Add more items as needed
        ]
      };
    },
    methods: {
      onButtonClick(item) {
        item.isActive = !item.isActive;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>

  
  .header-text {
    font-size: 16px;
    font-weight: 700;
    color: $txtColor;
    margin-bottom: 16px;
    margin-top: 24px;
  }
  
  .items-container {
    display: flex;
    flex-direction: column;
  }
  
  .item {
    padding: 16px;
    display: flex;
    align-items: center;
    height: 61px;
    margin-bottom: 10px; /* Space between items */
    background-color: $whiteColor; /* White background */
    border-radius: 16px;
  }
  
  .item-image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .item-text {
    flex-grow: 1;
  }
  
  .item-title {
    font-size: 14px;
    font-weight: 500;
    color: $txtColor;
    margin-bottom: 2px;
  }
  
  .item-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $txtSubColor;
  }
  
  .item-button {
    width: 70px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    border: none;
    outline: none;
    cursor: pointer;

  }
  
  .item-button.active {
    background-color: $themeColor;
    color: $whiteColor;
  }
  
  .item-button:not(.active) {
    background-color: $bgColor_5;
  }
  
  .button-icon {
    width: 16px;
    height: 16px;
  }
  </style>
  