<template>
	<div class="dropdown-container">
		<select v-model="selectedOption" class="custom-dropdown">
			<option v-for="(option, index) in options" :key="index" :value="option">
				{{ option }}
			</option>
		</select>
		<img src="@/assets/svg/ic_chevron_right.svg" alt="" class="ic_chevron_right">
	</div>
</template>

<script>
	export default {
		data() {
			return {
				selectedOption: "All Token", // 默认选项
				options: ["All Token"] // 下拉列表中的选项
			};
		}
	};
</script>

<style lang="scss" scoped>
	.dropdown-container {
		width: 95px;
		height: 31px;
		position: relative;
	}

	.custom-dropdown {
		width: 100%;
		height: 100%;
		font-size: 12px;
		color: $txtColor;
		padding: 0 15px 0 10px;
		border: 1px solid black;
		border-radius: 20px;
		background-color: white;
		appearance: none; /* 去掉默认的下拉箭头 */
		-webkit-appearance: none; /* 适用于Safari和Chrome */
		-moz-appearance: none; /* 适用于Firefox */
		/* 适用于Firefox */
		font-size: 14px;
		/* 适当的字号 */
		cursor: pointer;
		outline: none;
		/* 去掉点击后的边框 */
	}

	.custom-dropdown::-ms-expand {
		display: none;
		/* 去掉IE默认的下拉箭头 */
	}

	/* 添加自定义的下拉箭头 */
	.ic_chevron_right{
		position: absolute;
		top: 50%;
		right: 10px;
		width: 16px;
		height: 16px;
		transform: translateY(-50%);
		pointer-events: none;
	}
</style>