<template>
  <div class="outer-container">
    <div class="grid-container">
      <div v-for="item in items" :key="item.id" class="grid-item1">
        <FollowItem></FollowItem>
      </div>
    </div>
    <button id="connect_btn" class="floating-button" @click="connect">
      {{ $t("subscribeAll") }}
    </button>
  </div>
</template>

<script>
import FollowItem from '@/components/widget/FollowItem.vue';
import { TonConnect } from '@tonconnect/sdk';

export default {
  components: {
    FollowItem,
  },
  mounted() {
    // Initialize TonConnect with the manifest URL
    this.tonConnect = new TonConnect({
      manifestUrl: 'https://hello.foxsir.cn/tonconnect-manifest.json'
    });
    console.log('TonConnect initialized:', this.tonConnect);
  },

  data() {
    return {
      items: Array.from({ length:15 }, (_, i) => ({
        id: i + 1,
        name: `Item ${i + 1}`,
      })),
      walletAddress: null,
      tonConnect: null,
    };
  },
  methods:{
    async connect(){
      try {
        const walletConnection = await this.tonConnect.connect();
        this.walletAddress = walletConnection.account.address;
        console.log(this.walletAddress);
      } catch (error) {
        console.error('Failed to connect to wallet:', error);
      }

     
    }

  }
 

};
</script>

<style lang="scss" scoped >

.outer-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
 /* 高度设置为视口高度 */
  box-sizing: border-box; /* 包括边框和内边距在高度计算中 */
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px;
  /* Adjust this value based on your layout */
  overflow-y: auto;
  grid-auto-rows: minmax(157px, auto); /* 设置行的最小高度 */
}

.grid-item1 {
  border-radius: 20px;
  height: 157px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
}

.floating-button {
  position: fixed;
  bottom: 70px;
  width: 290px;
  height: 46px;
  background-color: $themeColor; /* Blue color */
  color: $whiteColor;
  border: none;
  border-radius: 23px; /* Half of the height for fully rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
}
</style>
