// store.js
import {
	createStore
} from 'vuex';

const store = createStore({
	state() {
		return {
			scrollPosition: 0,
			// 其他状态变量
		};
	},
	mutations: {
		saveScrollPosition(state, position) {
			state.scrollPosition = position;
		},
		// 其他变更
	},
	actions: {
		// 异步操作可以放在这里
	},
	getters: {
		// 计算属性
	},
});

export default store;