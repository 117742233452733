<template>
    <div class="container">
      <img src="@/assets/png/iv_success.png" alt="Logo" class="logo" />
      <h1 class="title">{{ $t('linkCreateCoinTitle') }}</h1>
      <p class="subtitle">{{ $t('linkCreateCoinContent') }}</p>
      <div class="spacer"></div>
      <button class="cta-button"  @click="gotoIndex">{{  $t('linkCreateCoinBtn')  }}</button>
      <p class="subtitle" @click="gotoIndex">{{ $t('linkCreateCoinLater') }}</p>
    </div>
</template>
  
  <script>
  export default {
    name: "LinkCreateCoinPage",
    methods: {
      gotoIndex() {
        this.$router.push("/index");
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-start;
    height: 100vh;
    padding: 0 24px 72px;
    box-sizing: border-box;
  }
  
  .logo {
    width: 96px;
    height: 98px;
    margin-top: 167px;
  }
  
  .title, .subtitle {
    width: 100%;
    text-align: center;
    box-sizing: border-box;

  }
  
  .title {
    font-weight: 700;
    font-size: 24px;
    margin-top: 20px;
    color: $txtColor;
  }
  
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    color: $txtSubColor;
  }
  
  .spacer {
    flex-grow: 1;
  }
  
  .cta-button {
    width: 289px;
    height: 46px;
    background-color: $themeColor;
    color: $whiteColor;
    border: none;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
  }
  </style>
  