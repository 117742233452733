import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/global.css';
import i18n from './language/i18n';
import store from './state/store.js'

// 禁用双指缩放
// document.addEventListener('gesturestart', function (e) {
//   e.preventDefault();
// });

// document.addEventListener('gesturechange', function (e) {
//   e.preventDefault();
// });

// document.addEventListener('gestureend', function (e) {
//   e.preventDefault();
// });

document.querySelectorAll('.scrollable').forEach(el => {
  el.addEventListener('touchmove', function (e) {
    e.stopPropagation();  // 阻止事件冒泡到窗口
  }, { passive: false });
});



createApp(App)
  .use(router)
  .use(i18n)  
  .use(store)
  .mount('#app');
