<template>
    <div class="card">
      <img 
        class="selector" 
        :src="isSelected ? selectedImage : unselectedImage" 
        @click="toggleSelect" 
        alt="selector"
      />
      <img class="profile-pic" :src="profileImage" alt="profile" />
      <div class="info-container">
        <span class="info-text">Tim Bakes</span>
        <div class="info-icons">
          <img class="icon" :src="icon1" alt="icon1" />
          <img class="icon" :src="icon2" alt="icon2" />
        </div>
      </div>
      <div class="subtext">@Tim Bakes</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isSelected: false,
        selectedImage: require('@/assets/svg/ic_round_select.svg'),
        unselectedImage: require('@/assets/svg/ic_round.svg'),
        profileImage: 'https://img2.woyaogexing.com/2020/02/29/98003fcdd64f4cbfac008ffd835efad3%21400x400.jpeg',
        icon1: require('@/assets/svg/ic_symble.svg'),
        icon2: require('@/assets/svg/ic_twitter_small.svg'),
      };
    },
    methods: {
      toggleSelect() {
        this.isSelected = !this.isSelected;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .card {
    width: 163px;
    height: 157px;
    background-color: $whiteColor;
    border-radius: 16px;
    position: relative;
    box-sizing: border-box;
  }
  
  .selector {
    position: absolute;
    top: 9.5px;
    widows: 16px;
    height: 16px;
    right: 9.5px;
    cursor: pointer;
  }
  
  .profile-pic {
    display: block;
    margin: 24px auto 0 auto;
    width: 50px; /* Adjust as per your image size */
    height: 50px; /* Adjust as per your image size */
    border-radius: 50%;
  }
  
  .info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
  
  .info-text {
    color: $txtColor;
    font-size: 14px;
    font-weight: 500;
  }
  
  .info-icons {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
  
  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
  
  .subtext {
    text-align: center;
    margin-top: 6px;
    color: $txtSubColor;
    font-size: 12px;
  }
  </style>
  