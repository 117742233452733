<!-- src/components/Home.vue -->
<template>
  <div>
    <div class="parent-container">
      <div class="container">
        <div class="widget">
          <CustomTabs @tab-changed="handleTabChange" />
        </div>
        <div class="icons">
          <img src="@/assets/svg/ic_msg.svg" alt="Icon 1" class="icon" @click="handleIconClick('msg')" />
          <img src="@/assets/svg/ic_add_friends.svg" alt="Icon 2" class="icon" @click="handleIconClick('add')" />
          <img src="@/assets/svg/ic_found.svg" alt="Icon 3" class="icon" @click="handleIconClick('found')" />
        </div>
      </div>
    </div>
   
    <div v-if="currentTopTabName === '0'">
      <FollowPage />
    </div>
    <div v-if="currentTopTabName === '1'">
      <HotPage />
     </div>
    <div v-if="currentTopTabName === '2'">
      <NewsPage />
    </div>
  </div>
</template>

<script>

import CustomTabs from "@/components/widget/CustomTabs.vue";
import FollowPage from "@/components/index/home/FollowPage.vue";
import HotPage from "@/components/index/home/HotPage.vue";
import NewsPage from "@/components/index/home/NewsPage.vue";


export default {
  name:"homePage",
  components: {
    CustomTabs,
    FollowPage,
    HotPage,
    NewsPage,
  },
  data() {
      return {
        currentTopTabName: "0",
      };
    },

  methods: {
    handleTabChange(tabName) {
      this.currentTopTabName = tabName;
      console.log(tabName);
    },

    handleIconClick(iconName) {
      console.log(iconName);
    },

   
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  align-items: center; /* 子元素上下居中 */
  height: 62px; /* 父容器高度 */
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.widget {
  width: 200px;
  height: 42px; /* 示例高度 */
  margin-left: 16px; /* 靠左边16px */
}

.icons {
  display: flex;
  gap: 16px; /* 图标之间的间距 */
  margin-left: auto; /* 将图标推到最右边 */
  padding-right: 16px; /* 靠右边16px */
}

.icon {
  width: 21px;
  height: 21px;
}
</style>
