<template>
    <div class="card">
      <FollowHeader  :info="userInfo"/>
      <CrowdfundingItem :info="userInfo"/> 
    </div>
  
  </template>
  
  <script>
  
  import FollowHeader from "@/components/widget/FollowHeader.vue";
  import CrowdfundingItem from "@/components/widget/CrowdfundingItem.vue";
  
  export default {
    components:{
      CrowdfundingItem,
      FollowHeader,

    },
    props: {
      info: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
 
        userInfo:this.info
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .card {
    background-color: $whiteColor;
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 1px;
    margin-bottom: 16px;
  }
  </style>
  