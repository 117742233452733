<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import LinkPage from './components/link/LinkPage.vue';

export default {
  components: {
    // LinkPage
  }
}
</script>

<style lang="scss" >

#app {
  display: flex;
  flex-direction: column;
  background: $bgColor;
  min-height: 100vh;
  margin: 0;
}

html {
  touch-action: manipulation; /* 防止在触摸设备上缩放 */
}

</style>
