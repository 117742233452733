<template>
    <div class="container">
      <div class="left-side">5 days ago</div>
      <div class="right-side">
        <div class="item" v-for="(item, index) in items" :key="index">
            <img :src="item.src" alt="Icon 1" class="icon"/>
          <span v-if="item.text" class="text">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          { src: require('@/assets/svg/ic_like.svg'), text: '12.3K' },
          { src: require('@/assets/svg/ic_commen.svg'), text: '1M' },
          { src: require('@/assets/svg/ic_share.svg') }
        ]
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .left-side {
    /* 适当的样式，例如字体大小 */
    font-size: 12px;
    color: $txtSubColor;
  }
  .text{
    font-size: 12px;
    color: $txtColor;

  }
  .right-side {
    display: flex;
    align-items: center;
  }
  
  .item {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  
  .item:last-child {
    margin-right: 0; /* 最后一项没有间距 */
  }
  
  .icon {
    margin-right: 3px; /* 图标和文字之间的间距 */
  }
  
  .text {
    /* 最右边图标样式 */
    
  }
  </style>
  