<template>
    <div class="card">
      <FollowHeader  :info="info"/>
      <div class="container">
        <div class="text-section">
          <p class="text-content">Telegram Star has been supported in @CatizenAIfor a month!Catizen has welcomed 82K Telegram Star paying users, who used 53.2 Million Stars (over 1 million USD)!</p>
        </div>
        <div class="image-section">
          <img src="https://img0.baidu.com/it/u=2728383854,1598173475&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800" alt="Descriptive Alt Text" class="image-content" />
        </div>
      </div>
      <BottomCommon class="marginBox"/>
    </div>
  
  </template>
  
  <script>
  
  import FollowHeader from "@/components/widget/FollowHeader.vue";
  import BottomCommon from "@/components/widget/BottomCommon.vue";
  
  export default {
    components:{
    
      FollowHeader,
      BottomCommon
    },

    props: {
      info: {
        type: Object,
        required: true,
      }
    },

    data() {
      return {
        imageSrc: 'https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690',
        iconSrc1: require('@/assets/svg/ic_symble.svg'),
        iconSrc2: require('@/assets/svg/ic_twitter_small.svg'),
       
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .card {
    background-color: $whiteColor;
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 1px;
    margin-bottom: 16px;
  }

  .container {
    padding: 0 16px; /* 左右边距16px */
  }
  
  .text-section {
    margin-bottom: 16px; /* 文字和图片之间的边距 */
  }
  
  .text-content {
    font-size: 14px;
    font-weight: 400;
  }
  .marginBox{
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .image-section {
    overflow: hidden; /* 防止图片溢出圆角 */
  }
  
  .image-content {
    width: 100%;
    height: 151px; /* 固定高度 */
    border-radius: 12px; /* 圆角12px */
    object-fit: cover; /* 图片铺满容器 */
  }
  </style>
  