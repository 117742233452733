<template>
  <div class="page-container">
    <div class="header">
      <span class="header-text">2,800</span>
      <img src="@/assets/png/iv_light.png" alt="Icon" class="header-icon" />
    </div>
    <div class="subheader">
     {{ $t('currentIntegral') }}
    </div>
    <div class="description">
      {{ $t('integralDesc') }}
    </div>
    <div>
      <TaskClass />

    </div>


  </div>
</template>
<script>
import TaskClass from '../widget/TaskClass.vue';

export default {
    components:{
      TaskClass
    }


}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 16px;
}

.header {
  margin-top: 43px;
  display: flex;
  align-items: center;
}

.header-text {
  font-size: 30px;
  font-weight: 700;
  color: $txtColor;
  margin-right: 8px; /* 距离右边的icon的间距 */
}

.header-icon {
  width: 24px;
  height: 29px;
}

.subheader {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: $txtSubColor;
}

.description {
  margin-top: 20px;
  padding: 12px 8.5px;
  background-color: $bgColor_3; /* 灰色背景 */
  color: $txtSubColor;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.5;
}
</style>
