<template>
	<div class="container">
		<div class="container-info">
			<!-- 头像 -->
			<div class="person-header">
				<img :src="userInfoData.headerImgUrl" alt="" class="img_header">
			</div>
			<!-- 资产信息 -->
			<div class="person-wallet">
				<img src="@/assets/svg/ic_wallet.svg" alt="" class="ic_wallet">
				<span class="person-money" v-text="userInfoData.money"></span>
				<img src="@/assets/svg/ic_arrow_right_up.svg" alt="" class="ic_arrow_right_up">
				<span class="person-percent" v-text="userInfoData.percent"></span>
			</div>
			<!-- 姓名/标签等 -->
			<div class="person-info">
				<div class="person-info-name">
					<span v-text="userInfoData.userName"></span>
					<img src="@/assets/svg/ic_sign1.svg" alt="" class="ic_sign">
					<img src="@/assets/svg/ic_sign2.svg" alt="" class="ic_sign">
				</div>
				<p class="timTitle">@tim</p>
				<div class="person-sign">
					<span class="person-sign-item" v-for="(item, index) in userInfoData.signArr" :key="index"
						v-text="item.label"></span>
				</div>
			</div>
		</div>

		<!-- 中间内容 -->
		<div class="container-center-info">
			<div class="container-center-data">
				<div class="center-data-item">
					<p class="center-data" v-text="userInfoData.shots"></p>
					<p class="center-data-title">{{ $t("personTitleShots") }}</p>
				</div>
				<div class="center-data-item">
					<p class="center-data" v-text="userInfoData.followers"></p>
					<p class="center-data-title">{{ $t("personTitleFollowers") }}</p>
				</div>
				<div class="center-data-item">
					<p class="center-data" v-text="userInfoData.following"></p>
					<p class="center-data-title">{{ $t("personTitleFollowing") }}</p>
				</div>
			</div>
			<div class="center-desc-info">
				<p class="text-content">
					<span v-text="userInfoData.remarkDesc"></span>
					<img src="@/assets/svg/ic_edit_line.svg" alt="" class="edit-icon">
				</p>
			</div>

			<!-- Total Stake -->
			<div class="center-total-stake">
				<p class="total_stake">
					<img src="@/assets/png/ic_tgr.png" alt="" class="ic_tgr">
					{{$t("personTitleTotalStake")}}
				</p>
				<div class="total_stake_data">
					<p class="total_stake_money">$503.12</p>
					<p class="total_stake_percent">
						<img src="@/assets/svg/ic_arrow_down.svg" alt="" class="ic_arrow_down">
						<span>12%</span>
					</p>
				</div>
				<div class="total_stake_crow_and_holders">
					<div class="total-stake-data-item">
						<p class="total-stake-data" v-text="userInfoData.crowdfunding"></p>
						<p class="total-stake-data-title">{{ $t("personTitleCrowdfunding") }}</p>
					</div>
					<div class="total-stake-data-item">
						<p class="total-stake-data" v-text="userInfoData.holders"></p>
						<p class="total-stake-data-title">{{ $t("personTitleHolders") }}</p>
					</div>
				</div>
				<!-- 进度条 -->
				<div class="progress-bar-container">
					<div class="progress-bar" :style="{'width': userInfoData.progress + '%'}"></div>
				</div>
			</div>

			<!-- Create Token -->
			<div class="center-create-token">
				<p class="create-token-desc">{{$t("personTitleCreateTokenDescTop")}}</p>
				<p class="create-token-desc">{{$t("personTitleCreateTokenDescDown")}}</p>
				<div class="createTokenBtn">
					<img src="@/assets/svg/ic_add_square.svg" alt="" class="ic_add_square">
					{{$t("personTitleCreateToken")}}
				</div>
			</div>
		</div>

		<!-- 底部选项卡 -->
		<div class="tab-container">
			<div class="tab-titles">
				<div v-for="(item, index) in tabs" :key="index"
					:class="['tab-title', { 'active': activeTab === index }]" @click="selectTab(index)">
					{{ item.tab }}
					<div v-if="activeTab === index" class="active-underline"></div>
				</div>
			</div>
			<div class="tab-content">
				<!-- 根据需要显示对应的内容 -->
				<template v-if="activeTab == 0">
					<div class="tab-assets-list">
						<div class="tab-assets-list-item" v-for="(tabItem, tabIndex) in tabs[activeTab].content"
							:key="tabIndex">
							<div class="tab-assets-list-item-img">
								<img :src="tabItem.iconUrl" alt="" class="ic_eth_latam">
							</div>
							<div class="tab-assets-list-item-person">
								<p class="tab-person-title" v-text="tabItem.title"></p>
								<p class="tab-person-money">
									<span class="tab-item-person-money" v-text="tabItem.leftMoney"></span>
									<img src="@/assets/svg/ic_arrow_right_up.svg" alt="" class="tab-item-person-icon">
									<span class="tab-item-person-percent" v-text="tabItem.leftPercent"></span>
								</p>
							</div>
							<div class="tab-assets-list-item-right">
								<p class="tab-right-title" v-text="tabItem.rightTitle"></p>
								<p class="tab-person-money">
									<span class="tab-item-person-money" v-text="tabItem.rightMoney"></span>
									<img src="@/assets/svg/ic_arrow_right_up.svg" alt="" class="tab-item-person-icon">
									<span class="tab-item-person-percent" v-text="tabItem.rightPercent"></span>
								</p>
							</div>
						</div>
					</div>
				</template>
				<template v-else-if="activeTab == 1">
					<CustomSelect />
					<div class="tab-trading-list">
						<div class="tab-trading-list-item" v-for="(tabItem, tabIndex) in tabs[activeTab].content"
							:key="tabIndex">
							<p class="tab-trading-date">
								{{tabItem.date}}
								<span class="tab-trading-type"
									:class="{'tab-trading-buy': tabItem.tradeType == 'BUY', 'tab-trading-sell': tabItem.tradeType == 'SELL'}"
									v-text="tabItem.tradeType"></span>
							</p>
							<p class="tab-trading-status"
								:class="{'tab-trading-status-success-bg': tabItem.tradeStatus == 1, 'tab-trading-status-lose-bg': tabItem.tradeStatus == 2}">
								<img src="@/assets/svg/ic_checkbox_circle_line1.svg" alt=""
									class="tab-trading-status-icon" v-if="tabItem.tradeStatus == 1">
								<img src="@/assets/svg/ic_circle_contained.svg" alt="" class="tab-trading-status-icon"
									v-if="tabItem.tradeStatus == 2">
								<span class="tab-trading-status-success"
									v-if="tabItem.tradeStatus == 1">{{$t('personTitleTradeSuccess')}}</span>
								<span class="tab-trading-status-lose"
									v-if="tabItem.tradeStatus == 2">{{$t('personTitleTradeLose')}}</span>
							</p>
							<div class="tab-trading-change">
								<template v-if="tabItem.tradeChangeType == 1">
									<img src="@/assets/png/ic_ton.png" alt="" class="tab-trading-change-icon">
									<span v-text="tabItem.tradeChangeFrom + 'TON'"></span>
									<img src="@/assets/svg/ic_arrow_right_line.svg" alt="" class="ic_arrow_right_line">
									<img src="@/assets/png/ic_avax.png" alt="" class="tab-trading-change-icon">
									<span v-text="tabItem.tradeChangeTo + 'AVAX'"></span>
								</template>
								<template v-if="tabItem.tradeChangeType == 2">
									<img src="@/assets/png/ic_dai.png" alt="" class="tab-trading-change-icon">
									<span v-text="tabItem.tradeChangeFrom + 'DAI'"></span>
									<img src="@/assets/svg/ic_arrow_right_line.svg" alt="" class="ic_arrow_right_line">
									<img src="@/assets/png/ic_ton.png" alt="" class="tab-trading-change-icon">
									<span v-text="tabItem.tradeChangeTo + 'TON'"></span>
								</template>

							</div>
							<p class="tab-trading-copy">
								<span v-text="tabItem.tradeCopyData"></span>
								<img src="@/assets/svg/ic_file_copy.svg" alt="" class="ic_file_copy">
							</p>
						</div>
					</div>
				</template>
				<template v-else-if="activeTab == 2">
					<div class="tab-dynamic-list">
						<div class="tab-dynamic-list-item" v-for="(tabItem, tabIndex) in tabs[activeTab].content"
							:key="tabIndex">
							<div class="tab-dynamic-operate">
								<img src="@/assets/svg/ic_more.svg" alt="" class="ic_more"
									@click="openOperate(tabIndex)">
								<button v-if="deleteIndex == tabIndex" class="delete-button"
									@click="deleteDynamic(tabIndex)">
									删除
								</button>
							</div>

							<div class="tab-dynamic-list-item-info">
								<div class="tab-assets-list-item-img">
									<img :src="tabItem.iconUrl" alt="" class="ic_eth_latam">
								</div>
								<div class="tab-assets-list-item-person">
									<p class="tab-person-title tab-dynamic-list-item-title">
										<span v-text="tabItem.title"></span>
										<img src="@/assets/svg/ic_sign1.svg" alt="" class="ic_sign">
										<img src="@/assets/svg/ic_sign2.svg" alt="" class="ic_sign">
										<span class="timTitle tab-dynamic-timTitle">@tim</span>
									</p>
									<p class="tab-person-money tab-dynamic-money">
										<span class="tab-item-person-money"
											v-text="tabItem.followers + ' followers'"></span>
									</p>
								</div>
							</div>
							<div class="tab-dynamic-list-item-details">
								<div class="tab-dynamic-list-item-details-top">
									<img :src="tabItem.dynamicImgUrl" alt="" class="ic_dynamic_item_img">
								</div>
								<div class="tab-dynamic-list-item-details-down">
									<span class="dynamic-time" v-text="tabItem.dynamicTime"></span>
									<div class="dynamic-details">
										<p class="dynamic-details-heart">
											<img src="@/assets/svg/ic_heart.svg" alt="" class="dynamic-details-icon">
											<span v-text="tabItem.dynamicHeart"></span>
										</p>
										<p class="dynamic-details-heart">
											<img src="@/assets/svg/ic_menu.svg" alt="" class="dynamic-details-icon">
											<span v-text="tabItem.dynamicMenu"></span>
										</p>
										<p class="dynamic-details-heart">
											<img src="@/assets/svg/ic_share.svg" alt="" class="dynamic-details-icon">
										</p>
									</div>
								</div>
							</div>
						</div>

						<!-- 添加按钮 -->
						<div class="addDynamicBtn" @click="addDynamicBtnClick">+</div>
					</div>
				</template>
			</div>
		</div>

		<!-- 发布内容遮罩层 -->
		<div class="releaseDynamicCover" v-if="isShowReleaseDynamicCover">
			<div class="releaseDynamicCont">
				<img src="@/assets/svg/ic_close.svg" alt="" class="ic_close" @click="closeReleaseDynamicCover">
				<span class="postBtn">{{$t('personTitlePostBtn')}}</span>
				<textarea class="text-input" placeholder="what is happening?" v-model="releaseDynamicInfo"></textarea>
				<div class="image-upload">
					<img :src="releaseDynamicImageUrl || placeholderImage" alt="Upload" class="upload-image" @click="triggerFileUpload" />
					<input type="file" ref="fileInput" @change="handleFileUpload" class="file-input" accept="image/*" />
				</div>
				<p class="syncToTwitter" @click="syncToTwitterClick">
					<img :src="syncToTwitter ? require('@/assets/svg/ic_checkbox_circle_line1.svg') : require('@/assets/svg/ic_checkbox_circle_line_unselect.svg')" alt="" class="ic_checkbox_circle">
					{{$t("personTitleSyncToTwitter")}}
				</p>
			</div>
		</div>
	
		<!-- 关注/粉丝列表遮罩层 -->
		<div class="followListCover" v-if="isShowFollowListCover">
			
		</div>
	</div>
</template>

<script>
	import CustomSelect from './person/CustomSelect.vue';
	export default {
		components: {
			CustomSelect
		},
		data() {
			return {
				userInfoData: {
					headerImgUrl: require("@/assets/png/img_header.png"), //头像
					money: "$1200", //金额
					percent: "12%", //比例
					userName: "Tim Bakes",
					signArr: [{
						label: "X Followers 628.7k",
						value: "X Followers 628.7k"
					}, {
						label: "Whale",
						value: "Whale"
					}, {
						label: "Coin Issued",
						value: "Coin Issued"
					}],
					shots: 400,
					followers: "6.92k",
					following: 20,
					remarkDesc: "I’m a expert with over 5years of professional of professional experienceof professional experience professional professional professionalprofessional",
					crowdfunding: "80%",
					holders: "28.7k",
					progress: 40
				},
				tabs: [{
					tab: "Assets",
					content: [{
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						leftMoney: "$23.98",
						leftPercent: "12%",
						rightTitle: "2.33",
						rightMoney: "$23.98",
						rightPercent: "12%",
						status: 1, //状态【1：升   2：降】
					}, {
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						leftMoney: "$23.98",
						leftPercent: "12%",
						rightTitle: "2.33",
						rightMoney: "$23.98",
						rightPercent: "12%",
						status: 1, //状态【1：升   2：降】
					}, {
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						leftMoney: "$23.98",
						leftPercent: "12%",
						rightTitle: "2.33",
						rightMoney: "$23.98",
						rightPercent: "12%",
						status: 1, //状态【1：升   2：降】
					}, {
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						leftMoney: "$23.98",
						leftPercent: "12%",
						rightTitle: "2.33",
						rightMoney: "$23.98",
						rightPercent: "12%",
						status: 1, //状态【1：升   2：降】
					}]
				}, {
					tab: "Trading",
					content: [{
						id: 1,
						date: "2024.07.12 15:30",
						tradeType: "BUY", //交易类型【BUY  SELL】
						tradeStatus: 1, //交易状态【1：success   2：lose】
						tradeChangeType: 1, //交易转换类型【1：ton->avax   2：dai->ton】
						tradeChangeFrom: 0.5,
						tradeChangeTo: 2.88,
						tradeCopyData: "EDNXJscduDcjaScsZXXS..liDak"
					}, {
						id: 1,
						date: "2024.07.12 15:30",
						tradeType: "SELL", //交易类型【BUY  SELL】
						tradeStatus: 2, //交易状态【1：success   2：lose】
						tradeChangeType: 2, //交易转换类型【1：ton->avax   2：dai->ton】
						tradeChangeFrom: 0.5,
						tradeChangeTo: 2.88,
						tradeCopyData: "EDNXJscduDcjaScsZXXS..liDak"
					}]
				}, {
					tab: "Dynamic",
					content: [{
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						followers: "628.7k",
						dynamicImgUrl: require("@/assets/png/ic_test_rectangle.png"),
						dynamicTime: "5 Days ago",
						dynamicHeart: "12.3K",
						dynamicMenu: "1M"
					}, {
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						followers: "628.7k",
						dynamicImgUrl: require("@/assets/png/ic_test_rectangle.png"),
						dynamicTime: "5 Days ago",
						dynamicHeart: "12.3K",
						dynamicMenu: "1M"
					}, {
						id: 1,
						iconUrl: require("@/assets/png/ic_eth_latam.png"),
						title: "Total Stake",
						followers: "628.7k",
						dynamicImgUrl: require("@/assets/png/ic_test_rectangle.png"),
						dynamicTime: "5 Days ago",
						dynamicHeart: "12.3K",
						dynamicMenu: "1M"
					}]
				}],
				activeTab: 2,
				deleteIndex: -1, //当前要删除的dynamic对应的索引

				releaseDynamicInfo: "", //发布动态内容
				releaseDynamicImageUrl: null, // 上传的图片URL
				placeholderImage: require("@/assets/png/ic_add_img.png"), // 默认占位图片
				syncToTwitter: false,  //是否同步到twitter
				isShowReleaseDynamicCover: false,  //是否显示发布动态遮罩层
				isShowFollowListCover: true,  //是否显示关注/粉丝列表遮罩层
			}
		},
		methods: {
			/**
			 * 切换选项卡事件
			 * @param {Object} index
			 */
			selectTab(index) {
				this.activeTab = index;
			},
			/**
			 *  tab-》dynamic下某项右侧操作按钮点击事件
			 * @param {Object} index
			 */
			openOperate(index) {
				let that = this;
				if (that.deleteIndex == index) {
					that.deleteIndex = -1;
				} else {
					that.deleteIndex = index;
				}
			},
			/**
			 * tab-》dynamic下某项删除按钮点击事件
			 * @param {Object} index
			 */
			deleteDynamic(index) {
				console.log("index:::", index)
			},
			/**
			 * tab-》dynamic添加动态按钮点击事件
			 */
			addDynamicBtnClick() {
				this.isShowReleaseDynamicCover = true;
			},
			/**
			 * 发布动态遮罩层 关闭 按钮点击事件
			 */
			closeReleaseDynamicCover() {
				this.isShowReleaseDynamicCover = false;
			},
			/**
			 * 发布动态遮罩层-》点击上传照片事件
			 */
			triggerFileUpload() {
				this.$refs.fileInput.click(); // 触发文件选择
			},
			/**
			 * 上传照片
			 * @param {Object} event
			 */
			handleFileUpload(event) {
				const file = event.target.files[0];
				if (file) {
					const reader = new FileReader();
					reader.onload = (e) => {
						this.releaseDynamicImageUrl = e.target.result; // 上传完成后回显图片
					};
					reader.readAsDataURL(file);
				}
			},
			/**
			 * 勾选同步到twitter切换事件
			 */
			syncToTwitterClick() {
				this.syncToTwitter = !this.syncToTwitter;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		padding-bottom: 70px;
		box-sizing: border-box;
	}

	/* 发布动态遮罩层 */
	.releaseDynamicCover {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.releaseDynamicCont {
		width: 343px;
		border-radius: 16px;
		background: #fff;
		padding: 16px;
		box-sizing: border-box;
		position: relative;
	}

	.ic_close {
		width: 24px;
		height: 24px;
		margin-top: 3px;
		float: left;
	}

	.postBtn {
		width: 79px;
		height: 28px;
		background: $themeColor;
		font-size: 12px;
		color: $whiteColor;
		border-radius: 20px;
		position: absolute;
		right: 16px;
		top: 16px;
		text-align: center;
		line-height: 28px;
	}

	.text-input {
		width: 100%;
		height: 84px;
		font-size: 14px;
		padding: 4px;
		border: 0px !important;
		border-radius: 4px;
		resize: none;
		box-sizing: border-box;
		font-family: "微软雅黑";
		color: $txtSubColor;
		margin-top: 7px;
	}

	.image-upload {
		margin-top: 12px;
		display: flex;
		justify-content: start;
		align-items: center;
	}

	.upload-image {
		width: 56px;
		height: 56px;
		object-fit: cover;
		border-radius: 8px;
		cursor: pointer;
	}

	.file-input {
		display: none;
	}
	.syncToTwitter{
		font-size: 14px;
		color: $txtSubColor;
		display: flex;
		align-items: center;
		position: absolute;
		right: 16px;
		bottom: 16px;
	}
	.ic_checkbox_circle{
		width: 16px;
		height: 16px;
		margin-right: 2px;
		margin-top: 2px;
	}



	/* 中间内容 */
	.container-center-info {
		margin: 20px 24px;
	}

	.container-center-data {
		overflow: auto;
	}

	.center-data-item,
	.total-stake-data-item {
		float: left;
		padding-bottom: 1px;
		margin-right: 56px;
	}

	.total-stake-data-item {
		margin-right: 29px;
	}

	.center-data,
	.total-stake-data {
		font-size: 20px;
		font-weight: bold;
		color: $txtColor;
		line-height: 24px;
	}

	.total-stake-data {
		font-size: 14px;
		line-height: 17px;
	}

	.center-data-title,
	.total-stake-data-title {
		font-size: 12px;
		line-height: 14px;
		color: $txtSubColor;
		margin-top: 5px;
	}

	.center-desc-info {
		margin: 16px 0;
	}

	.text-content {
		font-size: 14px;
		line-height: 21px;
		color: $txtColor_80;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
	}

	.edit-icon {
		width: 16px;
		height: 16px;
		position: absolute;
		right: 0;
		bottom: 2px;
		margin-left: 8px;
	}

	.center-total-stake,
	.center-create-token {
		padding: 16px;
		box-sizing: border-box;
		border-radius: 16px;
		border: 1px solid rgba(0, 0, 0, 0.08);
		overflow: auto;
		margin-top: 16px;
	}

	.center-create-token {
		background: rgba(39, 82, 231, 0.05);
		position: relative;
		padding: 28px 32px;
	}

	.center-create-token::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		padding: 1px;
		/* 边框厚度 */
		background: linear-gradient(to bottom, #2752E7, rgba(39, 82, 231, 0.01));
		/* 渐变颜色 */
		-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
	}

	.create-token-desc {
		text-align: center;
		font-size: 14px;
		color: #14171A;
		font-weight: 500;
		line-height: 20px;
	}

	.createTokenBtn {
		height: 40px;
		background: $themeColor;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		font-weight: 500;
		color: $whiteColor;
		border-radius: 19px;
		margin-top: 16px;
		position: relative;
		z-index: 10;
	}

	.ic_add_square {
		width: 20px;
		height: 20px;
		margin-right: 4px;
	}


	.total_stake {
		font-size: 14px;
		color: $txtColor;
		font-weight: bold;
		line-height: 24px;
		display: flex;
		align-items: center;
		float: left;
	}

	.ic_tgr {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-right: 6px;
	}

	.total_stake_data {
		float: right;
	}

	.total_stake_money {
		font-size: 16px;
		font-weight: bold;
		color: $txtColor;
		line-height: 19px;
	}

	.total_stake_percent {
		height: 14px;
		display: flex;
		align-items: center;
		justify-content: end;
		font-size: 12px;
		color: $txtColor_green;
		font-weight: 500;
		margin-top: 4px;
	}

	.ic_arrow_down {
		width: 14px;
		height: 14px;
	}

	.total_stake_crow_and_holders {
		float: left;
		width: 100%;
		margin-top: 16px;
	}

	.progress-bar-container {
		width: 100%;
		height: 8px;
		background-color: $bgColor_5;
		border-radius: 59px;
		overflow: hidden;
		margin-top: 16px;
		float: left;
	}

	.progress-bar {
		height: 100%;
		background-color: #FF5700;
		border-radius: 59px;
		transition: width 0.3s ease;
	}



	.container-info {
		width: 100%;

	}

	.person-header {
		width: 64px;
		height: 64px;
		margin-top: 29px;
		margin-left: 24px;
		border-radius: 50%;
		background-color: #ccc;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		float: left;
	}

	.img_header {
		width: 100%;
	}

	.person-wallet {
		height: 34px;
		padding: 0 8px 0 16px;
		box-sizing: border-box;
		border-radius: 20px 0 0 20px;
		background: $themeColor;
		float: right;
		margin-top: 44px;
		display: flex;
		align-items: center;
	}

	.ic_wallet {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	.person-money {
		font-size: 14px;
		color: #FFFFFF;
		font-weight: 700;
		line-height: 18px;
	}

	.ic_arrow_right_up {
		width: 10px;
		height: 10px;
		margin: 0 0 0 5px;
	}

	.person-percent {
		font-size: 10px;
		font-weight: bold;
		color: $txtColor_green1;
		line-height: 14px;
	}

	.person-info {
		float: left;
		width: 100%;
		margin-top: 16px;
		padding: 0 24px;
		box-sizing: border-box;
	}

	.person-info-name {
		font-size: 20px;
		font-weight: 500;
		color: $txtColor;
	}

	.person-info-name span {
		display: inline-block;
		max-width: calc(100% - 40px);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		float: left;
	}

	.ic_sign {
		width: 12px;
		height: 12px;
		margin-left: 5px;
	}

	.timTitle {
		color: $txtSubColor;
		font-size: 12px;
		margin: 3px 0 5px;
	}

	.person-sign {
		overflow: auto;
	}

	.person-sign-item {
		height: 18px;
		padding: 0 6px;
		box-sizing: border-box;
		background: $bgColor_5;
		color: $txtColor;
		margin-right: 3px;
		font-size: 11px;
		display: inline-block;
		line-height: 18px;
		border-radius: 2px;
	}

	/* 底部选项卡 */
	.tab-container {
		width: 100%;
	}

	.tab-titles {
		display: flex;
		justify-content: space-around;
		align-items: center;
		border-bottom: 1px solid #ddd;
	}

	.tab-title {
		height: 48px;
		line-height: 48px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.5);
		text-align: center;
		cursor: pointer;
		position: relative;
		flex-grow: 1;
	}

	.tab-title.active {
		color: rgba(0, 0, 0, 1);
	}

	.active-underline {
		width: 30px;
		height: 3px;
		background-color: #2752E7;
		border-radius: 12px 12px 0 0;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	.tab-content {
		padding: 25px 16px;
		box-sizing: border-box;
		font-size: 14px;
	}

	.tab-assets-list {
		padding: 0 8px;
		box-sizing: border-box;
	}

	.tab-assets-list-item {
		overflow: auto;
		padding-bottom: 1px;
		margin-bottom: 50px;
	}

	.tab-assets-list-item-img {
		width: 40px;
		height: 40px;
		overflow: hidden;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		float: left;
	}

	.ic_eth_latam {
		width: 100%;
	}

	.tab-assets-list-item-person,
	.tab-assets-list-item-right {
		float: left;
		margin-left: 12px;
	}

	.tab-assets-list-item-right {
		float: right;
	}

	.tab-person-title,
	.tab-right-title {
		font-size: 15px;
		color: $txtColor;
		font-weight: 500;
	}

	.tab-right-title {
		font-size: 14px;
		text-align: right;
	}

	.tab-person-money {
		font-size: 12px;
		color: $txtSubColor;
		height: 14px;
		display: flex;
		align-items: center;
		margin-top: 6px;
	}

	.tab-item-person-icon {
		width: 14px;
		height: 14px;
		margin: 0 2px;
	}

	.tab-item-person-percent {
		color: $txtColor_green1;
	}

	.tab-trading-list-item,
	.tab-dynamic-list-item {
		border: 1px solid rgba(0, 0, 0, 0.08);
		border-radius: 16px;
		padding: 18px 16px;
		box-sizing: border-box;
		margin-top: 12px;
		position: relative;
	}

	.tab-trading-date {
		font-size: 12px;
		line-height: 16px;
		color: $txtSubColor;
	}

	.tab-trading-type {
		height: 16px;
		padding: 0 5px;
		font-size: 10px;
		font-weight: 500;
		line-height: 16px;
		color: $whiteColor;
		border-radius: 2px;
		margin-left: 6px;
		display: inline-block;
	}

	.tab-trading-buy {
		background: $txtColor_green1;
	}

	.tab-trading-sell {
		background: $txtColor_red;
	}

	.tab-trading-status {
		height: 28px;
		padding: 0 8px;
		box-sizing: border-box;
		border-radius: 15px 0 0 15px;
		display: flex;
		align-items: center;
		position: absolute;
		right: 0;
		top: 12px;
	}

	.tab-trading-status-success-bg {
		background: $txtColor_green1_10;
	}

	.tab-trading-status-lose-bg {
		background: $txtColor_red_10;
	}

	.tab-trading-status-success,
	.tab-trading-status-lose {
		font-size: 12px;
		font-weight: 500;
		color: $txtColor_green1;
		margin-left: 4px;
	}

	.tab-trading-status-lose {
		color: $txtColor_red;
	}

	.tab-trading-status-icon {
		width: 16px;
		height: 16px;
	}

	.tab-trading-change {
		margin-top: 22px;
		height: 28px;
		display: flex;
		align-items: center;
	}

	.tab-trading-change-icon {
		width: 28px;
		height: 28px;
	}

	.tab-trading-change span {
		font-size: 16px;
		color: $txtColor;
		font-weight: 500;
		margin: 0 6px;
	}

	.ic_arrow_right_line {
		width: 24px;
		height: 24px;
		margin: 0 16px;
	}

	.tab-trading-copy {
		margin-top: 12px;
		background: $bgColor_3;
		padding: 12px 16px;
		box-sizing: border-box;
		border-radius: 8px;
		font-size: 12px;
		color: $txtSubColor;
		display: flex;
		align-items: center;
		position: relative;
	}

	.ic_file_copy {
		width: 16px;
		height: 16px;
		position: absolute;
		right: 16px;
	}

	.tab-dynamic-list-item {
		margin-top: 0 !important;
		margin-bottom: 12px !important;
		padding: 16px !important;
	}

	.tab-dynamic-list-item-info {
		overflow: auto;
		padding-bottom: 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	}

	.tab-dynamic-list-item-title {
		display: flex;
		align-items: center;
	}

	.tab-dynamic-timTitle {
		margin-left: 4px !important;
	}

	.tab-dynamic-operate {
		float: right;
		margin-top: 8px;
		position: relative;
	}

	.ic_more {
		width: 24px;
		height: 24px;
	}

	.tab-dynamic-money {
		margin-top: 2px !important;
	}

	.tab-dynamic-list-item-details {
		margin-top: 16px;
	}

	.tab-dynamic-list-item-details-top {
		border-radius: 12px;
		overflow: hidden;
	}

	.ic_dynamic_item_img {
		width: 100%;
		float: left;
	}

	.tab-dynamic-list-item-details-down {
		margin-top: 12px;
	}

	.dynamic-time {
		font-size: 12px;
		color: $txtSubColor;
	}

	.dynamic-details {
		float: right;
		height: 100%;
	}

	.dynamic-details-heart {
		float: left;
		font-size: 12px;
		color: $txtColor_80;
		margin-left: 16px;
		display: flex;
		align-items: center;
	}

	.dynamic-details-icon {
		width: 15px;
		height: 15px;
		margin-right: 4px;
	}

	.delete-button {
		position: absolute;
		top: 30px;
		/* 根据需要调整按钮的位置 */
		right: 0;
		padding: 6px 12px;
		font-size: 12px;
		color: $txtColor;
		background-color: $bgColor_5;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		white-space: nowrap;
	}

	.delete-button:hover {
		background-color: #d9363e;
	}

	.addDynamicBtn {
		width: 56px;
		height: 56px;
		background: $themeColor;
		font-size: 24px;
		color: $whiteColor;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 16px;
		bottom: 100px;
	}
</style>

