<template>
  <nav class="bottom-nav">
    <a href="#" @click.prevent="navigateTo('0')" class="nav-item" :class="{ active: currentTab === '0' }">
      <img :src="getIconSrc('/home',currentTab)" alt="Home" class="icon" />
      <span>Home</span>
    </a>
    <a href="#" @click.prevent="navigateTo('1')" class="nav-item" :class="{ active: currentTab === '1' }">
      <img :src="getIconSrc('/person',currentTab)" alt="person" class="icon" />
      <span>PERSONAL</span>
    </a>
    <a href="#" @click.prevent="navigateTo('2')" class="nav-item" :class="{ active: currentTab === '2' }">
      <img :src="getIconSrc('/task',currentTab)" alt="task" class="icon" />
      <span>TASK</span>
    </a>
  </nav>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup(props, { emit }) {
    const route = useRoute();
    const currentTab = ref('0'); // 默认选中第一个Tab

    const navigateTo = (tabIndex) => {
      currentTab.value = tabIndex;
      emit('bottom-tab-changed', tabIndex);
    };
    emit('bottom-tab-changed', '0');
    return {
      currentTab,
      currentPath: route.path,
      navigateTo,
    };
  },
  methods: {
    getIconSrc(path,currentTab) {
      console.log("getIconSrc: "+path);
      const isActive = (currentTab === '0' && path === '/home') || 
      (currentTab === '1' && path === '/person') ||
      (currentTab === '2' && path === '/task');
      const iconName = path.slice(1); // 去掉路径中的 '/'
      return isActive
        ? require(`@/assets/png/ic_${iconName}_select.png`)
        : require(`@/assets/png/ic_${iconName}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  display: flex;
  height: 60px;
  justify-content: space-around;
  background: $whiteColor;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 4px $bgColor_1;
}

.nav-item {
  color: $txtSubColor;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 18px;
  height: 21px;
  margin-bottom: 5px;
}

.nav-item.active {
  color: $txtColor;
}
</style>
