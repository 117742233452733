// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/index/HomePage.vue';
import Person from '@/components/index/PersonPage.vue';
import Task from '@/components/index/TaskPage.vue';
import LinkCreateCoin from '@/components/link/LinkCreatePage.vue';
import LinkPage from '@/components/link/LinkPage.vue';
import IndexPage from '@/components/index/IndexPage.vue';
import FollowPage from '@/components/index/home/FollowPage.vue';
import HotPage from '@/components/index/home/HotPage.vue';
import NewsPage from '@/components/index/home/NewsPage.vue';

const routes = [
  { path: '/', component: LinkPage },

  { path: '/linkCreateCoin', component: LinkCreateCoin },

  {
    path: '/index', component: IndexPage,
    redirect: '/home',
    children: [
      {
        path: '/home', 
        name:'homePage',
        component: Home,
        meta: { keepAlive: true },
        children: [
          {
            path: '/home/follow', component: FollowPage,
            meta: { keepAlive: true },
          },
          {
            path: '/home/hot', component: HotPage,
            meta: { keepAlive: true },
          },
          {
            path: '/home/news', component: NewsPage,
            meta: { keepAlive: true },
          },
        ]
      },
      {
        path: '/person', component: Person,
        name:'PersonPage',
        meta: { keepAlive: true }
      },
      {
        path: '/task', component: Task,
        name:'TaskPage'
        , meta: { keepAlive: true }
      },
      // { path: '/contact', component: Contact },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.onError((error) => {
  console.error('路由错误:', error);
});

export default router;
