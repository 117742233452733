// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    welcome: 'Welcome',
    linkTitle: 'Link Your Twitter',
    linkContent: 'Link your Twitter account to verify your identity and make it easier for friends to discover and trade your tokens',
    linkButton: 'Link',
    linkCreateCoinTitle: 'Welcome StarFriend！',
    linkCreateCoinContent: 'We have detected that you are a quality KOL, you can create your personal token immediately, and after the token is created, you can earn income based on your personal influence!',
    linkCreateCoinBtn: 'Create Token',
    linkCreateCoinLater: 'Later on',
    personTitleShots: 'Shots',  //个人中心页面
    personTitleFollowers: 'followers',  //个人中心页面
    personTitleFollowing: 'following',  //个人中心页面
    personTitleTotalStake: 'Total Stake',  //个人中心页面
    personTitleCrowdfunding: 'Crowdfunding',  //个人中心页面
    personTitleHolders: 'Holders',  //个人中心页面
    personTitleCreateTokenDescTop: 'Create Your Own Token',  //个人中心页面
    personTitleCreateTokenDescDown: 'And Monetize Your Influence!',  //个人中心页面
    personTitleCreateToken: 'Create Token',  //个人中心页面
    personTitleTradeSuccess: 'Success',  //个人中心页面
    personTitleTradeLose: 'Lose',  //个人中心页面
    personTitlePostBtn: 'Post',  //个人中心页面
    personReleaseDynamicPlaceholder: 'what is happening?',  //个人中心页面
    personTitleSyncToTwitter: "sync to twitter",  //个人中心页面
    subscribeAll: 'Subscribe All',
    following: 'Following',
    follow: 'Follow',
    currentIntegral:'Current integral',
    integralDesc:'After completing the task, you will immediately earn points. We will distribute airdrops based on these points later.'
  },

  zh: {
    welcome: '欢迎',
    splashTitle: '',
    linkContent: '',
    linkButton: '',
    linkCreateCoinTitle: '',
    linkCreateCoinContent: '',
    linkCreateCoinBtn: '',
    linkCreateCoinLater: '',
    subscribeAll: '',
    following: '',
    follow: '',
    personTitleShots: '',  //个人中心页面
    personTitleFollowers: '',  //个人中心页面
    personTitleFollowing: '',  //个人中心页面
    personTitleTotalStake: '',  //个人中心页面
    personTitleCrowdfunding: '',  //个人中心页面
    personTitleHolders: '',  //个人中心页面
    personTitleCreateTokenDescTop: '',  //个人中心页面
    personTitleCreateTokenDescDown: '',  //个人中心页面
    personTitleCreateToken: '',  //个人中心页面
    personTitleTradeSuccess: '',  //个人中心页面
    personTitleTradeLose: '',  //个人中心页面
    personTitlePostBtn: '',  //个人中心页面
    personReleaseDynamicPlaceholder: '',  //个人中心页面
    personTitleSyncToTwitter: "",  //个人中心页面
    currentIntegral:''
  },

};

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;


// switchLanguage(language) {
//   this.$i18n.locale = language;
//   localStorage.setItem('language', language);
//   window.location.reload(); // 刷新页面以应用新语言
// }