<template>
  <div class="container">
    <div class="content-area">
      <HomePage v-show="currentTabName === '0'" />
      <PersonPage v-show="currentTabName === '1'" />
      <TaskPage v-show="currentTabName === '2'" />
    </div>
   
    <BottomNav @bottom-tab-changed="changeTab" class="bottom-nav"></BottomNav>
  </div>
</template>

<script>
import BottomNav from '@/components/widget/BottomNav.vue';
import HomePage from '@/components/index/HomePage.vue'; // 假设路径为 '../pages/HomePage.vue'
import PersonPage from '@/components/index/PersonPage.vue'; // 假设路径为 '../pages/PersonPage.vue'
import TaskPage from '@/components/index/TaskPage.vue'; // 假设路径为 '../pages/TaskPage.vue'

export default {
  name: "indexPage",
  components: {
    BottomNav,
    HomePage,
    PersonPage,
    TaskPage
  },
  data() {
    return {
      currentTabName: '/home', // 初始化为首页或其他默认页面
    };
  },
  methods: {
    changeTab(tabName) {
      this.currentTabName = tabName;
      // console.log('当前选中的标签是:', tabName);
    },
  },
};
</script>
<style scoped>

.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* 使容器高度为视口高度 */
}
.content-area {
  flex: 1; /* 使内容区占据剩余空间 */
  overflow-y: auto; /* 如果内容超出视口高度，允许滚动 */
}

.bottom-nav {
  height: 60px; /* 根据 BottomNav 的高度设置 */
  flex-shrink: 0; /* 确保 BottomNav 不会被压缩 */
}
</style>


