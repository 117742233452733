<template>
  <div>
    <div class="outer-container">
      <pull-refresh v-model="loading" @refresh="onRefresh">
    
      <div v-for="item in datas" :key="item.id">
        <HotCrowdfundingItem :info="item" v-if="item.type === 1" />
        <HotTxtPicItem :info="item" v-if="item.type === 2" />
      </div>
    </pull-refresh>
    </div>

  </div>
</template>

<script>
import HotCrowdfundingItem from "@/components/widget/HotCrowdfundingItem.vue";
import HotTxtPicItem from "@/components/widget/HotTxtPicItem.vue";
import PullRefresh from "pull-refresh-vue3";
export default {
  components: {
    HotCrowdfundingItem,
    HotTxtPicItem,
    PullRefresh,
  },

  methods: {
    onRefresh() {
      setTimeout(() => {
        // 模拟数据更新
        this.datas.unshift({
          id: 5,
          name: "Tim Bakes1",
          follower: "628.7k followers",
          headPic:
            "https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690",
          crowdfundPic:
            "https://img2.baidu.com/it/u=2400211421,1271830035&fm=253&fmt=auto&app=138&f=JPEG?w=570&h=380",
          showVip: true,
          showTwitter: true,
          isFollowed: false,
          type: 1,
        });
        this.loading = false; // 完成刷新
      }, 1000);
    },
  },
  data() {
    return {
      loading: false,
      datas: [
        {
          id: 2,
          name: "Tim Bakes2",
          follower: "628.7k followers",
          headPic:
            "https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690",
          crowdfundPic:
            "https://img2.baidu.com/it/u=2400211421,1271830035&fm=253&fmt=auto&app=138&f=JPEG?w=570&h=380",
          showVip: true,
          showTwitter: true,
          isFollowed: false,
          type: 2,
        },
        {
          id: 1,
          name: "Tim Bakes1",
          follower: "628.7k followers",
          headPic:
            "https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690",
          crowdfundPic:
            "https://img2.baidu.com/it/u=2400211421,1271830035&fm=253&fmt=auto&app=138&f=JPEG?w=570&h=380",
          showVip: true,
          showTwitter: true,
          isFollowed: false,
          type: 1,
        },
       
        {
          id: 3,
          name: "Tim Bakes3",
          follower: "628.7k followers",
          headPic:
            "https://img0.baidu.com/it/u=4290471493,194702659&fm=253&fmt=auto?w=690&h=690",
          crowdfundPic:
            "https://img2.baidu.com/it/u=2400211421,1271830035&fm=253&fmt=auto&app=138&f=JPEG?w=570&h=380",
          type: 1,
          showVip: true,
          showTwitter: true,
          isFollowed: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.heightBox {
  height: 20px;
}
.lb-pull-refresh  {
  overflow: auto;
}
.outer-container {
  overflow-y: auto;
  height: calc(100vh - 122px);
  box-sizing: border-box; /* 包括边框和内边距在高度计算中 */
}
</style>
