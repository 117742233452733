<template>
  <div class="container">
    <!-- <p>Current URL: {{ currentUrl }}</p>
    <p>Query Parameters: {{ queryParams }}</p>

    <h1>Telegram Web App</h1>
    <button @click="getUserId">Get User ID</button>
    <p>User ID: {{ userId }}</p>
    <p>Datas: {{ datas }}</p>
 -->

    <img src="@/assets/svg/ic_twitter.svg" alt="Logo" class="logo" />
    <h1 class="title">{{ $t("linkTitle") }}</h1>
    <p class="subtitle">{{ $t("linkContent") }}</p>
    <div class="spacer"></div>
    <button class="cta-button" @click="gotoLinkCreatCoin">
      {{ $t("linkButton") }}
    </button>
  </div>
</template>
  
<script>
export default {
  name: "linkPage",

  methods: {
    gotoLinkCreatCoin() {
      this.$router.push("/linkCreateCoin");
    },
 
  },
  mounted() {
    // 获取当前网页的 URL
    this.currentUrl = window.location.href;

    // 获取 URL 查询参数
    const urlParams = new URLSearchParams(window.location.search);
    this.queryParams = Object.fromEntries(urlParams.entries());

    // Dynamically load the Telegram Web Apps SDK
    // this.setAppHeight();
    // window.addEventListener('resize', this.setAppHeight);
    
  },
 
  data() {
    return {
      currentUrl: '',
      queryParams: {},
      userId: "null",
      datas: "",
    };
  },
};
</script>
  
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 0 24px 72px;
  box-sizing: border-box;

}

.logo {
  width: 96px;
  height: 98px;
  margin-top: 167px;
}

.title,
.subtitle {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.title {
  font-weight: 700;
  font-size: 24px;
  margin-top: 20px;
  color: $txtColor;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  color: $txtSubColor;
}

.spacer {
  flex-grow: 1;
}

.cta-button {
  width: 289px;
  height: 46px;
  background-color: $themeColor;
  color: $whiteColor;
  border: none;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
</style>
  